import React from 'react';
import { bgModal } from '../../assets';

const Alert = () => {
  return (
    <div className="   ">
      <div
        className="bg-no-repeat bg-cover "
        style={{
          backgroundImage: `url(${bgModal})`,
          // width: `${dimensions.width > 1000 ? '850px' : '350px'}`,
          // height: `${dimensions.height > 700 ? '500 ' : '350'}`,
        }}
      >
        <div className="flex flex-col justify-center py-10 px-24 sm:p-5 gap-5">
          <div className=" flex justify-center ">
            <svg
              width="200"
              height="170"
              viewBox="0 0 248 170"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_87_47)">
                <path
                  d="M188.663 58.89C185.387 42.2927 176.452 27.3474 163.381 16.6066C150.311 5.86592 133.917 -0.00382436 117 1.86943e-06C88.8225 1.86943e-06 64.35 15.99 52.1625 39.39C37.8323 40.9386 24.5798 47.7283 14.9515 58.4544C5.32322 69.1805 -0.00167409 83.0863 3.94804e-07 97.5C3.94804e-07 129.773 26.2275 156 58.5 156H185.25C212.16 156 234 134.16 234 107.25C234 81.51 214.012 60.645 188.663 58.89ZM104.423 119.828C103.52 120.731 102.449 121.448 101.27 121.938C100.09 122.427 98.8257 122.679 97.5488 122.679C96.2718 122.679 95.0074 122.427 93.8279 121.938C92.6484 121.448 91.577 120.731 90.675 119.828L70.2 99.45C69.2973 98.5473 68.5813 97.4757 68.0928 96.2963C67.6042 95.1169 67.3528 93.8528 67.3528 92.5762C67.3528 89.9981 68.377 87.5255 70.2 85.7025C72.023 83.8795 74.4956 82.8553 77.0738 82.8553C79.6519 82.8553 82.1245 83.8795 83.9475 85.7025L97.5 99.255L141.18 55.575C143.003 53.752 145.476 52.7278 148.054 52.7278C150.632 52.7278 153.104 53.752 154.928 55.575C156.751 57.398 157.775 59.8706 157.775 62.4487C157.775 65.0269 156.751 67.4995 154.928 69.3225L104.423 119.828Z"
                  fill="white"
                  fillOpacity="0.95"
                  shape-rendering="crispEdges"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_87_47"
                  x="0"
                  y="0"
                  width="248"
                  height="170"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="7" dy="7" />
                  <feGaussianBlur stdDeviation="3.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_87_47"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_87_47"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <div className="sm:flex sm:flex-col gap-5 ">
            <div className=" mb-5 ">
              <p className="font-ff-primary text-3xl text-white text-center   sm:text-base 	">
                Terima Kasih Ya <br />
                Team Kami Akan Segera Menyapa Kamu
              </p>
            </div>
            <div className="mb-5">
              <p className="text-white font-ff-primary text-xl text-center   sm:text-base	">
                Untuk mengetahui info lebih lengkap, dapat menghubungi <br />{' '}
                Call Center kami di kontak atau layanan chat kami
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
