import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    padding: '0px',
    borderRadius: '30px',
  },
};

const ModalComponent = ({ isOpen, children, closeModal }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        overlayClassName="Overlay"
        contentLabel="modal"
        onRequestClose={closeModal}
        closeTimeoutMS={200}
        ariaHideApp={false}
      >
        {children}
      </Modal>
    </div>
  );
};

export default ModalComponent;
