import { useLayoutEffect, useState } from 'react'

const useScroll = () => {
    const [scrollY, setScrollY] = useState(window.screenY);

    useLayoutEffect(() => {
        function handleScroll() {
            setScrollY(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return [scrollY]
}

export default useScroll
