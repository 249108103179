import { http, api } from '../../configs'

class MemberService {
    checkMember(nomorAnggota) {

        return http.get(`${api.member.check}?memberNo=${nomorAnggota}`);
    }

    registerMember(data) {
        return http.post(`${api.member.register}`, data)
    }

    newRegisterMember(data) {
        return http.post(`${api.member.newRegister}`, data)
    }
}

export default new MemberService();
