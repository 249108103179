/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { icPlay } from '../../assets';
import { Modal, Video } from '..';
import { Bounce, Fade } from 'react-reveal';
import useScroll from '../../hooks/useScroll';
import { VideoService } from '../../services';
import { useQuery } from 'react-query';

const Content = () => {
  const getVideo = async () => {
    const YouTubeGetID = (url) => {
      var ID = '';
      url = url
        .replace(/(>|<)/gi, '')
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      } else {
        ID = url;
      }
      return ID;
    };

    try {
      const response = await VideoService.getVideo();
      let data = [];
      for (let index = 0; index < response.data.length; index++) {
        const item = response.data[index];

        let responseData = {
          id: item.id,
          title: item.title,
          content: item.content,
          videoUrl: YouTubeGetID(item.videoUrl),
        };

        data.push(responseData);
      }
      return data;
    } catch (error) {}
  };

  const { isLoading, data: dataVideo } = useQuery('video', getVideo);
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [scrollY] = useScroll();

  const playVideo = (url) => {
    setIsOpen(true);
    setUrl(url);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dotsClass: 'box-dots',
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 775,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <Video url={url} />
      </Modal>
      <div className="bg-bg-hitam  w-screen pb-20">
        <Fade left when={scrollY > 450 ? true : false}>
          <div className="text-center py-16">
            <h1 className="text-4xl text-white font-ff-secondary">
              Video Tutorial & Testimonial
            </h1>
          </div>
        </Fade>

        <div className="px-48 lg:px-14 s-400:px-2 video  ">
          {isLoading ? (
            ''
          ) : (
            <Slider {...settings}>
              {dataVideo?.map((item, index) => (
                <Bounce
                  top
                  delay={100 * index}
                  when={scrollY > 450 ? true : false}
                  key={item.id}
                >
                  <div className="relative ">
                    <div className="mx-auto p-4 flex flex-col min-h-100 ">
                      <div
                        className="bx-sh"
                        style={{
                          backgroundImage: `url("https://i.ytimg.com/vi/${item.videoUrl}/hqdefault.jpg")`,
                          minHeight: '381px',
                          width: '100%',
                          visibility: 'visible',
                          borderTopLeftRadius: '0.5rem',
                          borderTopRightRadius: '0.5rem',
                        }}
                      ></div>
                      <div
                        className="play-btn w-20 z-10 "
                        onClick={() => playVideo(item.videoUrl)}
                        style={{ width: '60px', height: '60px' }}
                      >
                        <img src={icPlay} alt="icon play" />
                      </div>

                      <div className="bg-white  rounded-b-20px bx-sh w-full pt-10 px-4 relative h-full ">
                        <div style={{ minHeight: '170px' }}>
                          <h5 className="font-ff-primary font-bold mt-5 text-sm  ">
                            {item.title}
                          </h5>

                          <p className=" text-sm mt-5   ">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Bounce>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};

export default Content;
