import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const HeaderSkeleton = () => {
  return (
    <div className="mt-5 mb-5  sm:mb-10 sm:p-5 py-20 ">
      <div className="grid grid-cols-2 md:grid-cols-1 s-400:mt-5  ">
        <div className="flex flex-col justify-center  sm:px-0 pl-20 s-400:pl-0 sm:pl-0   md:pl-0   ">
          <div className="container  items-center justify-center  ">
            <div className="mb-5">
              <Skeleton
                width={'80%'}
                height={100}
                borderRadius="1rem"
                baseColor="#ebab34"
              />
            </div>
            <div className="mb-5">
              <Skeleton
                count={3}
                width={'80%'}
                height={20}
                borderRadius="1rem"
                baseColor="#ebab34"
              />
            </div>
            <div className="mb-5">
              <Skeleton
                width={'30%'}
                height={50}
                borderRadius="1rem"
                baseColor="#ebab34"
              />
            </div>
          </div>
        </div>

        <div className="mb-5">
          <Skeleton
            width={'80%'}
            height={'100%'}
            borderRadius="1rem"
            baseColor="#ebab34"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderSkeleton;
