import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Disclamer, Landing, PrivacyPolicy, Selengkapnya, TaC } from '../pages';

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="syarat-dan-ketentuan" element={<TaC />} />
      <Route path="selengkapnya/:id" exact element={<Selengkapnya />} />
      <Route path="privacy-policy" exact element={<PrivacyPolicy />} />
      <Route path="disclamer" exact element={<Disclamer />} />
    </Routes>
  );
};

export default Routing;
