import React from 'react';

const Loading = ({ isLoading }) => {
  return (
    <div
      style={{
        display: isLoading ? 'block' : 'none',
      }}
    >
      <div className="loading">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loading;
