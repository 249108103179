import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Modal } from '../..';
import { GA, MemberService } from '../../../services';

const Member = () => {
  const navigate = useNavigate();
  const member = localStorage.getItem('member');

  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const [noAnggota, setNoAnggota] = useState('');
  const [nama, setNama] = useState('');
  const [noHp, setNoHp] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);

    if (!isMember) {
      setIsError(true);
      setErrMsg('Tekan Tombol Search ');
    } else if (!isChecked) {
      setIsError(true);
      setErrMsg('Harap Mencentang Syarat dan Ketentuan');
    } else {
      setIsLoading(true);
      try {
        await MemberService.registerMember({ memberNo: noAnggota });
        setIsLoading(false);
        setIsOpen(true);
        GA.event('Registrasi Agen Oren', 'Sukses Registrasi Agen Oren');
        setTimeout(() => {
          setIsOpen(false);
        }, 12000);
        localStorage.removeItem('member');
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        setErrMsg('Nomor Anggota Sudah Terdaftar');
        GA.event('Registrasi Agen Oren', 'Gagal Registrasi Agen Oren');
        localStorage.removeItem('member');
      }
    }
  };

  const getMember = async (noMember) => {
    setNama('');
    setNoHp('');
    setEmail('');
    setIsError(false);
    setIsMember(false);

    if (noMember === '') {
      setIsError(true);
      setErrMsg('Masukan Nomor Anggota');
      setTimeout(() => {
        setIsError(false);
        setErrMsg('');
      }, 3000);
    } else {
      try {
        setIsLoading(true);
        GA.event('Registrasi Agen Oren', 'Cari Nomor Anggota');
        const response = await MemberService.checkMember(noMember);
        setIsMember(true);
        GA.event('Registrasi Agen Oren', 'Sukses Cari Anggota');
        localStorage.setItem('member', JSON.stringify(response.data));
        setIsLoading(false);
      } catch (error) {
        localStorage.removeItem('member');
        setIsLoading(false);
        setIsError(true);
        setErrMsg('Nomor Anggota Tidak Ditemukan');
        GA.event('Registrasi Agen Oren', 'Gagal Cari Anggota');
      }
    }
  };

  useEffect(() => {
    let member = JSON.parse(localStorage.getItem('member'));

    if (member) {
      setNoAnggota(member.kode_reff);
      setNama(member.nama_ktp);
      setNoHp(member.no_hp);
      setEmail(member.email);
      setIsMember(true);
    }
  }, [member]);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <Alert />
      </Modal>
      <div className="p-14 sm:p-5 md:p-5 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              No. Anggota <span className="text-red-600">*</span>
            </label>
            <div className="col-span-8">
              <div className="flex">
                <input
                  type="number"
                  className="  h-10 rounded-tl-lg rounded-bl-lg  sm:h-10 flex-1 pl-5	"
                  placeholder="  Isi Nomor Anggota"
                  value={noAnggota}
                  onChange={(e) => setNoAnggota(e.target.value)}
                  required
                />

                <div
                  className=" bg-oren-300 rounded-tr-lg rounded-br-lg   sm:px-0 lg:px-0 cursor-pointer "
                  onClick={() => getMember(noAnggota)}
                >
                  <p className="text-white font-ff-secondary mt-3 lg:px-3 text-sm px-2  ">
                    {isLoading ? 'Mencari' : 'Search'}
                  </p>
                </div>
              </div>
              {isError && <p className="text-red-600 ">{errMsg}</p>}
            </div>
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              Nama Lengkap
            </label>
            <input
              type="text"
              className={`col-span-8 h-10 rounded-lg px-5 sm:h-10 cursor-not-allowed  `}
              readOnly={true}
              value={nama}
            />
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11  sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2  sm:mb-3">
              No. Handphone
            </label>
            <input
              type="text"
              className=" col-span-8 h-10 rounded-lg px-5  cursor-not-allowed	"
              value={noHp}
              readOnly={true}
            />
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              Email
            </label>
            <input
              type="text"
              className=" col-span-8 h-10 rounded-lg px-5 cursor-not-allowed 	"
              readOnly={true}
              value={email}
            />
          </div>

          <div className="grid grid-cols-12  md:mt-10 ">
            <div className=" col-span-4 sm:col-span-12	"></div>

            <div className="col-span-8 sm:col-span-12">
              <div className="flex  justify-start items-center ">
                <input
                  type="checkbox"
                  name="termsandcondition"
                  id="termsandcondition"
                  className="w-4 h-4 "
                  onChange={(e) => setIsChecked(e.target.checked)}
                />

                <label htmlFor="termsandcondition" className="ml-3">
                  <div onClick={() => navigate('/syarat-dan-ketentuan')}>
                    <p className="text-oren-300 font-ff-primary underline w-full ">
                      Setuju Syarat & Ketentuan
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 mt-5">
            <div className=" col-span-4 sm:col-span-12	"></div>

            <button
              type="submit"
              className=" col-span-8 h-12 rounded-3xl px-5 bg-oren-300 sm:col-span-12  	"
            >
              <p className="font-ff-primary text-white font-semibold">Kirim</p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Member;
