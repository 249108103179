import { http, api } from '../../configs'

class PrivacyPolicyService {
    getPrivacyPolicy(type) {
        return http.get(`${api.privacyPolicy}?type=${type}`);
    }


}

export default new PrivacyPolicyService();
