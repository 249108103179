import React, { useEffect, useState } from 'react';
import { ScrollingProvider } from 'react-scroll-section';
import { Loading } from './components';
import { QueryClient, QueryClientProvider } from 'react-query'

import Routing from './Route';

const queryClient = new QueryClient()

function App() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.onreadystatechange = function () {
      setIsLoading(true);
      if (document.readyState === 'complete') {
        setIsLoading(false);

      }
    };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>

        <ScrollingProvider>
          <Loading isLoading={isLoading} />
          <div className="overflow-x-hidden">
            <Routing />
          </div>
        </ScrollingProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
