import { api, http } from "../../../configs";

class VideoService {


    getVideo(size) {
        return http.get(`${api.content.video}`);
    }


}

export default new VideoService();
