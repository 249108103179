import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubFooter = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="block bg-hitam-footer w-full">
        <div className="flex justify-around">
          <p className=" font-ff-primary text-gray-200 p-5 text-xs">
            Copyright ©2021 All rights reserved | This template is made with by
            KOPNUSPOS
          </p>

          <div className=" font-ff-primary text-gray-200 p-5 text-xs text-right">
            <div className="flex space-x-5 cursor-pointer">
              <div onClick={() => navigate('/privacy-policy')}>
                <div>Privacy Policy</div>{' '}
              </div>{' '}
              <div>|</div>{' '}
              <div onClick={() => navigate('/disclamer')}>
                <div> Disclaimer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
