import React from 'react';
import { bgModal } from '../../assets';

const WaCall = (props) => {
  const phoneTransform = (num) => {
    let removeFirstEl = num.split('').slice(1).join('');

    return `62${removeFirstEl}`;
  };

  return (
    <div className="   ">
      <div
        className="bg-no-repeat bg-cover "
        style={{
          backgroundImage: `url(${bgModal})`,
        }}
      >
        <div className="flex flex-col justify-center py-10 px-24 sm:p-5 gap-5">
          <div className="sm:flex sm:flex-col gap-5 ">
            <div className=" mb-5 ">
              <p className="font-ff-primary text-3xl text-white text-center   sm:text-base 	">
                Hubungi call center kami
              </p>
              <div className="mt-6">
                {props.contacts.map((num, index) => {
                  return (
                    <div
                      className="flex flex-row justify-center items-center"
                      key={num.id}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.12 4.12994C11.96 0.799939 7.55005 -0.190061 4.13005 1.87994C0.800046 3.94994 -0.279954 8.44994 1.88005 11.7799L2.06005 12.0499L1.34005 14.7499L4.04005 14.0299L4.31005 14.2099C5.48005 14.8399 6.74005 15.1999 8.00005 15.1999C9.35005 15.1999 10.7 14.8399 11.87 14.1199C15.2 11.9599 16.19 7.54994 14.12 4.12994ZM12.23 11.0599C11.87 11.5999 11.42 11.9599 10.79 12.0499C10.43 12.0499 9.98005 12.2299 8.18005 11.5099C6.65005 10.7899 5.39005 9.61994 4.49005 8.26994C3.95005 7.63994 3.68005 6.82994 3.59005 6.01994C3.59005 5.29994 3.86005 4.66994 4.31005 4.21994C4.49005 4.03994 4.67005 3.94994 4.85005 3.94994H5.30005C5.48005 3.94994 5.66005 3.94994 5.75005 4.30994C5.93005 4.75994 6.38005 5.83994 6.38005 5.92994C6.47005 6.01994 6.47005 6.19994 6.38005 6.28994C6.47005 6.46994 6.38005 6.64994 6.29005 6.73994C6.20005 6.82994 6.11005 7.00994 6.02005 7.09994C5.84005 7.18994 5.75005 7.36994 5.84005 7.54994C6.20005 8.08994 6.65005 8.62994 7.10005 9.07994C7.64005 9.52994 8.18005 9.88994 8.81005 10.1599C8.99005 10.2499 9.17004 10.2499 9.26005 10.0699C9.35005 9.88994 9.80005 9.43994 9.98005 9.25994C10.16 9.07994 10.25 9.07994 10.43 9.16994L11.87 9.88994C12.05 9.97994 12.23 10.0699 12.32 10.1599C12.41 10.4299 12.41 10.7899 12.23 11.0599Z"
                          fill="#F8F8F8"
                        />
                      </svg>
                      <a
                        href={`https://wa.me/${phoneTransform(
                          num.contactNumber
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className="ml-2 text-white text-xl">
                          {num.contactLabel}
                        </p>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaCall;
