import React, { useEffect, useRef, useState } from 'react';
import { useScrollSection } from 'react-scroll-section';
import { lgKopnuspos } from '../../../assets';
import { FileService } from '../../../services';

function NavMobile() {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const outsideComponent = useRef();

  const promotionSection = useScrollSection('promotion');
  const videoSection = useScrollSection('video');
  const pendaftaranSection = useScrollSection('pendaftaran');
  const kontakSection = useScrollSection('kontak');

  const [webLogo, setWebLogo] = useState('');
  const [defaultLogo, setDefaultLogo] = useState(false);

  const getLogo = async (logoFile) => {
    let res;

    if (
      logoFile.endsWith('.png') ||
      logoFile.endsWith('.jpg') ||
      logoFile.endsWith('.jpeg') ||
      logoFile.endsWith('.svg')
    ) {
      res = await FileService.getFile(logoFile);
    } else {
      res = await FileService.getFile('noimage.png');
    }

    setWebLogo(res.data);
  };

  useEffect(() => {
    const getFileName = localStorage.getItem('filename');
    if (getFileName) {
      getLogo(getFileName);
    } else {
      setDefaultLogo(true);
    }
  }, []);

  const handleClick = (e) => {
    if (outsideComponent.current.contains(e.target)) {
      return;
    }

    setIsMenuShow(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div ref={outsideComponent}>
      <div className="w-screen nav-mobile bg-white fixed z-50 animate-fadeIn ">
        <div className=" flex flex-wrap justify-between px-16 py-5 animate-fadeIn sm:px-10">
          <div className=" self-center  md:flex md:justify-between md:items-center md:w-full ">
            <img
              src={
                !defaultLogo && webLogo !== ''
                  ? `data:image/jpeg;base64,${webLogo} `
                  : lgKopnuspos
              }
              alt="logo"
              style={{ height: '25px' }}
            />
            <div
              className="hidden md:block"
              onClick={() => setIsMenuShow(!isMenuShow)}
            >
              <svg
                viewBox="0 0 100 80"
                width="30"
                height="30"
                className="bg-white"
              >
                <rect width="100" height="20"></rect>
                <rect y="30" width="100" height="20"></rect>
                <rect y="60" width="100" height="20"></rect>
              </svg>
            </div>
          </div>
          <div
            className={`flex space-x-7 md:flex-col md:w-full md:space-x-0 md:pt-5 ${
              !isMenuShow ? 'hidden' : 'animate-fadeIn'
            }`}
          >
            <div onClick={promotionSection.onClick} className="cursor-pointer">
              <div className="text-black py-4 font-ff-secondary  ">
                Promotion
              </div>
              <div
                className={`rounded-lg ${
                  promotionSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div onClick={videoSection.onClick} className="cursor-pointer">
              <div className="text-black py-4 font-ff-secondary">
                Video Tutorial
              </div>
              <div
                className={`rounded-lg ${
                  videoSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div
              className="cursor-pointer"
              onClick={pendaftaranSection.onClick}
            >
              <div className="text-black py-4 font-ff-secondary">
                Form Pendaftaran
              </div>
              <div
                className={`rounded-lg ${
                  pendaftaranSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={kontakSection.onClick}>
              <div className="text-black py-4  font-ff-secondary">Kontak</div>
              <div
                className={`rounded-lg ${
                  kontakSection.selected ? 'bg-oren-100' : 'bg-white'
                }  bg-white w-full h-1`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMobile;
