import React, { useState } from 'react';
import { Alert, Modal } from '../..';
import { GA, MemberService } from '../../../services';

const NewMember = () => {
  const [radioSelected, setRadioSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [isNoHpError, setIsNoHpError] = useState(false);
  const [isKotaError, setIsKotaError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isYangLainError, setIsYangLainError] = useState(false);
  const [isRadioError, setIsRadioError] = useState(false);

  const [nama, setNama] = useState('');
  const [noHp, setNoHp] = useState('');
  const [kota, setKota] = useState('');
  const [email, setEmail] = useState('');
  const [yangLain, setYangLain] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsNameError(false);
    setIsNoHpError(false);
    setIsKotaError(false);
    setIsEmailError(false);
    setIsRadioError(false);
    setErrMsg('');

    if (nama === '' && noHp === '' && kota === '' && email === '') {
      setIsNameError(true);
      setIsNoHpError(true);
      setIsKotaError(true);
      setIsEmailError(true);
      setErrMsg('Semua forms masih kosong, tolong isi semua');
    } else if (nama === '') {
      setIsNameError(true);
      setErrMsg('Masukan Nama Lengkap');
    } else if (noHp === '') {
      setIsNoHpError(true);
      setErrMsg('Masukan Nomor Handphone');
    } else if (kota === ' ') {
      setIsKotaError(true);
      setErrMsg('Masukan Nama Kota');
    } else if (email === '') {
      setIsEmailError(true);
      setErrMsg('Masukan Email');
    } else if (radioSelected === '') {
      setIsRadioError(true);
      setErrMsg('Pilih Salah Satu');
    } else if (radioSelected === '4' && yangLain === '') {
      setIsYangLainError(true);
      setErrMsg('Masukan Sumber ');
    } else {
      let body = {
        city: kota,
        email,
        fullName: nama,
        phoneNumber: noHp,
        source:
          radioSelected === '1'
            ? 'Teman'
            : radioSelected === '2'
            ? 'Keluarga'
            : radioSelected === '3'
            ? 'Social Media'
            : radioSelected === '4'
            ? yangLain
            : '',
      };

      MemberService.newRegisterMember(body).then((response) => {
        setIsOpen(true);
        GA.event(
          'Tertarik Jadi Agen Oren',
          'Sukses Daftar Tertarik Jadi Agen Oren'
        );
        setTimeout(() => {
          setIsOpen(false);
        }, 12000);
      });

      setNama('');
      setNoHp('');
      setYangLain('');
      setKota('');
      setEmail('');
      setRadioSelected('');
    }
  };

  const handleRadioSelected = (e) => {
    setRadioSelected(e.target.value);
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <Alert />
      </Modal>
      <div className="p-14 sm:p-5 md:p-5 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              Nama Lengkap <span className="text-red-600">*</span>
            </label>

            <div className="col-span-8">
              <input
                type="text"
                className="  h-10 rounded-lg px-5 sm:h-10	w-full"
                placeholder="Nama Kamu"
                onChange={(e) => setNama(e.target.value)}
                required
                value={nama}
              />
              {isNameError && <p className="text-red-600 mt-1">{errMsg}</p>}
            </div>
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11  sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2  sm:mb-3">
              No. Handphone <span className="text-red-600">*</span>
            </label>
            <div className="col-span-8">
              <input
                type="number"
                className=" col-span-8 h-10 rounded-lg px-5 w-full 	"
                placeholder="085xxxxxx"
                onChange={(e) => setNoHp(e.target.value)}
                required
                value={noHp}
              />
              {isNoHpError && <p className="text-red-600 mt-1">{errMsg}</p>}
            </div>
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2  sm:mb-3">
              Kota <span className="text-red-600">*</span>
            </label>

            <div className=" col-span-8">
              <input
                type="text"
                className=" col-span-8 h-10 rounded-lg px-5 w-full 	"
                placeholder="Nama Kota"
                onChange={(e) => setKota(e.target.value)}
                required
                value={kota}
              />
              {isKotaError && <p className="text-red-600 mt-1">{errMsg}</p>}
            </div>
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              Email <span className="text-red-600">*</span>
            </label>
            <div className=" col-span-8">
              <input
                type="text"
                className=" h-10 rounded-lg px-5 w-full 	"
                placeholder="you@example.com"
                onChange={(e) => setEmail(e.target.value)}
                required
                value={email}
              />
              {isEmailError && <p className="text-red-600 mt-1">{errMsg}</p>}
            </div>
          </div>

          <div className="grid grid-cols-12 lg:mb-2 mb-11 sm:grid-cols-1 sm:mb-3">
            <label className="text-white col-span-4	 font-ff-primary mt-2 sm:mb-3 ">
              Dari manakah anda mengetahui Oren ?
              <span className="text-red-600">*</span>{' '}
            </label>

            <div className=" col-span-8">
              <div className="grid grid-rows 12">
                <div className="row span-3 flex flex-col">
                  <div className="mb-3">
                    <input
                      type="radio"
                      className="w-5 h-5 align-bottom cursor-pointer	"
                      id="teman"
                      name="teman"
                      value="1"
                      checked={radioSelected === '1'}
                      onChange={handleRadioSelected}
                    />
                    <label
                      htmlFor="teman"
                      className="text-white ml-3 align-middle "
                    >
                      Teman
                    </label>
                  </div>

                  <div className="mb-3">
                    <input
                      type="radio"
                      className="w-5 h-5 align-bottom cursor-pointer "
                      id="keluarga"
                      name="keluarga"
                      value="2"
                      checked={radioSelected === '2'}
                      onChange={handleRadioSelected}
                    />
                    <label
                      htmlFor="keluarga"
                      className="text-white ml-3 align-middle  "
                    >
                      Keluarga
                    </label>
                  </div>

                  <div className="mb-3">
                    <input
                      type="radio"
                      className="w-5 h-5 align-bottom cursor-pointer"
                      id="sosmed"
                      name="sosmed"
                      value="3"
                      checked={radioSelected === '3'}
                      onChange={handleRadioSelected}
                    />
                    <label
                      htmlFor="sosmed"
                      className="text-white ml-3 align-middle "
                    >
                      Social Media
                    </label>
                  </div>

                  <div className="mb-3">
                    <input
                      type="radio"
                      className="w-5 h-5 align-bottom cursor-pointer"
                      id="yang lain"
                      name="yang lain"
                      value="4"
                      checked={radioSelected === '4'}
                      onChange={handleRadioSelected}
                    />
                    <label
                      htmlFor="yang lain"
                      className="text-white ml-3 align-middle "
                    >
                      Yang Lain
                    </label>
                    {radioSelected === '4' && (
                      <>
                        <input
                          type="text"
                          className="ml-4 bg-black  border-b-2 border-white text-white cursor-pointer remove-border 	"
                          onChange={(e) => setYangLain(e.target.value)}
                          value={yangLain}
                        />
                        {isYangLainError && (
                          <p className="text-red-600 mt-1">{errMsg}</p>
                        )}
                      </>
                    )}
                  </div>
                  {isRadioError && (
                    <p className="text-red-600 mt-1">{errMsg}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 mb-2">
            <label className=" col-span-4	 "></label>

            <button
              type="submit"
              className=" col-span-8 h-12 rounded-3xl px-5 bg-oren-300 sm:col-span-12	"
            >
              <p className="font-ff-primary text-white font-semibold">Kirim</p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewMember;
