import React, { useEffect, useState } from 'react';
import { useScrollSection } from 'react-scroll-section';
import { lgKopnuspos } from '../../../assets';
import { FileService } from '../../../services';

const Navbar = () => {
  const promotionSection = useScrollSection('promotion');
  const videoSection = useScrollSection('video');
  const pendaftaranSection = useScrollSection('pendaftaran');
  const kontakSection = useScrollSection('kontak');

  const [webLogo, setWebLogo] = useState('');
  const [defaultLogo, setDefaultLogo] = useState(false);

  const [scroll, setScroll] = useState(1);

  const getLogo = async (fileName) => {
    let res;

    if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.svg')
    ) {
      res = await FileService.getFile(fileName);
    } else {
      res = await FileService.getFile('noimage.png');
    }

    setWebLogo(res.data);
  };

  useEffect(() => {
    const getFileName = localStorage.getItem('filename');
    if (getFileName) {
      getLogo(getFileName);
    } else {
      setDefaultLogo(true);
    }
  }, []);

  useEffect(() => {
    function handleScroll() {
      setScroll(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`${
          scroll > 142
            ? 'w-screen nav-web bg-white fixed z-50 animate-fadeIn '
            : ''
        }`}
      >
        <div
          className={`${
            scroll > 142
              ? ' flex flex-wrap justify-between px-16 py-5 animate-fadeIn  '
              : 'hidden'
          }`}
        >
          <div className=" self-center  ">
            <img
              src={
                !defaultLogo && webLogo !== ''
                  ? `data:image/jpeg;base64,${webLogo} `
                  : lgKopnuspos
              }
              width={150}
              alt="logo"
            />
          </div>
          <div
            className={`flex space-x-7 sm:flex-col sm:w-full sm:space-x-0 sm:pt-5`}
          >
            <div onClick={promotionSection.onClick} className="cursor-pointer">
              <div className="text-black py-4 font-ff-secondary  ">
                Promotion
              </div>
              <div
                className={`rounded-lg ${
                  promotionSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div onClick={videoSection.onClick} className="cursor-pointer">
              <div className="text-black py-4 font-ff-secondary">
                Video Tutorial
              </div>
              <div
                className={`rounded-lg ${
                  videoSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div
              className="cursor-pointer"
              onClick={pendaftaranSection.onClick}
            >
              <div className="text-black py-4 font-ff-secondary">
                Form Pendaftaran
              </div>
              <div
                className={`rounded-lg ${
                  pendaftaranSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>

            <div className="cursor-pointer" onClick={kontakSection.onClick}>
              <div className="text-black py-4  font-ff-secondary">Kontak</div>
              <div
                className={`rounded-lg ${
                  kontakSection.selected ? 'bg-oren-100' : 'bg-bg-hitam'
                }  bg-white w-full h-1`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
