import create from 'zustand'
import { ConfigurationService } from '../services'

const useStore = create(set => ({
    configData: {},
    fetchConfig: async () => {
        const response = await ConfigurationService.getConfiguration()
        localStorage.setItem('filename', response.data.webLogo.fileName)
        set({ configData: response.data })

    }
}))


export default useStore