import { useLayoutEffect, useState } from 'react'

const useWindowDimension = () => {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize, false);
    }, []);


    return [dimensions]
}

export default useWindowDimension
