import { api, http } from "../../../configs";

class BannerService {

    getBanner(size) {
        return http.get(`${api.content.banner}?size=${size}`);
    }

    getBannerById(id) {
        return http.get(`${api.content.banner}/${id}`)
    }


}

export default new BannerService();
