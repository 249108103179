import React, { useEffect, useState } from 'react';

import { lgKopnuspos } from '../../../assets';
import { FileService } from '../../../services';

const NavBanner = (props) => {
  const [webLogo, setWebLogo] = useState('');
  const [defaultLogo, setDefaultLogo] = useState(false);

  const getLogo = async (logoFile) => {
    let res;

    if (
      logoFile.endsWith('.png') ||
      logoFile.endsWith('.jpg') ||
      logoFile.endsWith('.jpeg') ||
      logoFile.endsWith('.svg')
    ) {
      res = await FileService.getFile(logoFile);
    } else {
      res = await FileService.getFile('noimage.png');
    }

    setWebLogo(res.data);
  };

  useEffect(() => {
    const getFileName = localStorage.getItem('filename');
    if (getFileName) {
      getLogo(getFileName);
    } else {
      setDefaultLogo(true);
    }
  }, []);
  return (
    <div className="md:hidden flex justify-between  mt-4 ">
      <div className="flex-1 self-center">
        <img
          src={
            !defaultLogo && webLogo !== ''
              ? `data:image/jpeg;base64,${webLogo} `
              : lgKopnuspos
          }
          alt="logo"
          width={150}
          style={{ filter: 'brightness(0) invert(1)' }}
        />
      </div>
      <div className="flex space-x-16 flex-2 lg:space-x-3  ">
        <div
          onClick={props.promotionSection.onClick}
          className="cursor-pointer"
        >
          <div className="text-white py-4 font-ff-secondary">Promotion</div>
          <div
            className={`rounded-lg ${
              props.promotionSection.selected ? 'bg-oren-100' : 'bg-white'
            }  bg-white w-full h-1`}
          ></div>
        </div>

        <div onClick={props.videoSection.onClick} className="cursor-pointer">
          <div className="text-white py-4 font-ff-secondary">
            Video Tutorial
          </div>
          <div
            className={`rounded-lg ${
              props.videoSection.selected ? 'bg-oren-100' : 'bg-white'
            }  bg-white w-full h-1`}
          ></div>
        </div>

        <div
          className="cursor-pointer"
          onClick={props.pendaftaranSection.onClick}
        >
          <div className="text-white py-4 font-ff-secondary">
            Form Pendaftaran
          </div>
          <div
            className={`rounded-lg ${
              props.pendaftaranSection.selected ? 'bg-oren-100' : 'bg-white'
            }  bg-white w-full h-1`}
          ></div>
        </div>

        <div className="cursor-pointer" onClick={props.kontakSection.onClick}>
          <div className="text-white py-4  font-ff-secondary">Kontak</div>
          <div
            className={`rounded-lg ${
              props.kontakSection.selected ? 'bg-oren-100' : 'bg-white'
            }  bg-white w-full h-1`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default NavBanner;
