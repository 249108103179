import ReactGa from 'react-ga';

const TRACK_ID = process.env.REACT_APP_GA_TRACK_ID;

const GA = {};

GA.pageView = (pathname) => {
    ReactGa.initialize(TRACK_ID);
    ReactGa.pageview(pathname);
};

GA.event = (category, action) => {
    ReactGa.event({
        category: category,
        action: action,
    });
};

export default GA;
