import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { bgOren, lgKopnuspos } from '../../assets';
import { SubFooter } from '../../components';
import { BannerService, FileService, GA } from '../../services';
import { Helmet } from 'react-helmet';

const Selengkapnya = () => {
  const navigate = useNavigate();

  let { id } = useParams();

  const [bannerState, setBannerState] = useState([]);

  const getById = async () => {
    try {
      const { data } = await BannerService.getBannerById(window.atob(id));

      let res;

      if (
        data.image.fileName.endsWith('.png') ||
        data.image.fileName.endsWith('.jpg') ||
        data.image.fileName.endsWith('.jpeg') ||
        data.image.fileName.endsWith('.svg')
      ) {
        res = await FileService.getFile(data.image.fileName);
      } else {
        res = await FileService.getFile('noimage.png');
      }

      let bannerData = {
        id: data.id,
        image: (
          <img
            src={`data:image/jpeg;base64,${res.data}`}
            alt={data.image.fileName}
          />
        ),
        title: data.title,
        subTitle: data.subTitle,
        description: window.atob(data.description),
      };

      setBannerState(bannerData);
    } catch (error) {}
  };

  useEffect(() => {
    getById();
    GA.pageView(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Banner {bannerState && `| ${bannerState.title && bannerState?.title}`}
        </title>
        <meta
          name="description"
          content={`${bannerState.subtitle}` || 'Tentang Banner'}
        />
      </Helmet>
      <div className="overflow-x-auto">
        <div
          className=" bg-no-repeat bg-cover py-10 px-20 s-400:px-5  s-500:px-5 w-full "
          style={{ backgroundImage: `url(${bgOren})` }}
        >
          <div className="glass-effect">
            <div className="flex justify-between items-center p-20 sm:p-10 ">
              <div
                className="flex items-center	gap-2 cursor-pointer"
                onClick={() => navigate('/')}
              >
                <svg
                  width="11"
                  height="20"
                  viewBox="0 0 11 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6195 0.989793C10.5034 0.873385 10.3655 0.781028 10.2136 0.718012C10.0617 0.654995 9.89893 0.622559 9.73451 0.622559C9.5701 0.622559 9.40729 0.654995 9.25543 0.718012C9.10357 0.781028 8.96563 0.873385 8.84951 0.989793L0.539515 9.29979C0.446811 9.39231 0.373263 9.5022 0.323082 9.62317C0.272901 9.74414 0.24707 9.87383 0.24707 10.0048C0.24707 10.1358 0.272901 10.2654 0.323082 10.3864C0.373263 10.5074 0.446811 10.6173 0.539515 10.7098L8.84951 19.0198C9.33951 19.5098 10.1295 19.5098 10.6195 19.0198C11.1095 18.5298 11.1095 17.7398 10.6195 17.2498L3.37951 9.99979L10.6295 2.74979C11.1095 2.26979 11.1095 1.46979 10.6195 0.989793Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white text-base">Back</p>
              </div>

              <img src={lgKopnuspos} alt="logo" className="md:hidden" />
            </div>
            <div className="grid grid-cols-12 px-10 pb-10 s-850:grid-cols-1 sm:px-0 ">
              <div className="col-span-6  sm:mx-5 	">
                <div className="	text-center">
                  <p className="text-5xl text-white font-ff-secondary lg:mb-20 s-850:mb-5 sm:text-4xl ">
                    {bannerState.title}
                  </p>
                  <p className="text-white font-ff-secondary mt-3">
                    {bannerState.subTitle}
                  </p>
                </div>
                <div className="flex justify-center mt-5">
                  {bannerState.image}
                </div>
              </div>
              <div className="col-span-6  sm:pr-0 sm:mx-5 sm:mt-5 s-850:mt-5">
                <div className="bg-white rounded-30px px-8 py-11  sm:px-5 ">
                  <div className="container h-37rem  overflow-scroll">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: bannerState.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubFooter />
      </div>
    </>
  );
};

export default Selengkapnya;
