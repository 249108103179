import React, { useEffect, useState } from 'react';
import { imgPromo } from '../../assets';
import Member from './member';
import NewMember from './newMember';
import { Fade } from 'react-reveal';

const Register = () => {
  const [radioSelected, setRadioSelected] = useState('1');

  const handleRadioSelected = (e) => {
    setRadioSelected(e.target.value);
    localStorage.setItem('form', e.target.value);
  };

  useEffect(() => {
    let selectedRadio = localStorage.getItem('form');
    if (selectedRadio) {
      setRadioSelected(selectedRadio);
    }
  }, []);

  return (
    <>
      <div className="w-full  ">
        <div className="bg-black w-screen">
          <div className="grid grid-cols-12 sm:grid-cols-1 ">
            <div className="col-span-5 w-full h-full ">
              <img
                src={imgPromo}
                alt="Promo sale"
                style={{ width: '100%', height: '100%' }}
                className="object-cover	sm:object-scale-down"
              />
            </div>
            <div className="col-span-7 w-full h-full  4xl:relative px-10 py-10">
              <div className="  4xl:p-14">
                <Fade left>
                  <h1 className="text-center  text-4xl font-bold font-ff-secondary mt-10 text-oren-300 md:text-2xl md:mt-5">
                    {radioSelected === '1'
                      ? 'Tertarik Jadi Agen Oren ? '
                      : 'Registrasi Agen Oren'}
                  </h1>
                </Fade>

                <div className="flex justify-center gap-10 sm:gap-2 mt-10 sm:my-4  lg:mt-5 lg:mb-0 mx-2 lg:gap-0 sm:justify-between  s-600:py-5 s-400:flex-col ">
                  <div className="flex items-center  s-400:py-5">
                    <input
                      type="radio"
                      name="radio1"
                      id="radio1"
                      className="w-5 h-5"
                      checked={radioSelected === '1'}
                      value="1"
                      onChange={handleRadioSelected}
                    />
                    <label htmlFor="radio1">
                      <p className="text-white font-ff-primary ml-1.5">
                        Belum Anggota KOPNUSPOS
                      </p>
                    </label>
                  </div>
                  <div className="flex items-center ">
                    <input
                      type="radio"
                      name="radio2"
                      id="radio2"
                      className="w-5 h-5 "
                      checked={radioSelected === '2'}
                      value="2"
                      onChange={handleRadioSelected}
                    />
                    <label htmlFor="radio2">
                      <p className="text-white font-ff-primary ml-1.5">
                        Anggota KOPNUSPOS
                      </p>
                    </label>
                  </div>
                </div>
                {radioSelected === '1' ? <NewMember /> : <Member />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
