/* eslint-disable jsx-a11y/iframe-has-title */

import React from 'react';
import useWindowDimension from '../../hooks/useWindowDimension';

const Video = ({ url }) => {
  const [dimensions] = useWindowDimension();

  return (
    <div>
      <iframe
        id="ytplayer"
        type="text/html"
        width={
          dimensions.width > 1000 ? '850' : dimensions.width > 500 ? 600 : 350
        }
        height={dimensions.height > 700 ? '500 ' : '250'}
        // width="850"
        // height="500"
        src={`https://www.youtube.com/embed/${url}?autoplay=1`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
