import { api, http } from '../../configs'



class ConfigurationService {

    getConfiguration() {
        return http.get(`${api.configuration}`);
    }


}

export default new ConfigurationService();
