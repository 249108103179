/* eslint-disable no-unused-expressions */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Fade } from 'react-reveal/';
import HeaderSkeleton from './skeleton';
import { BannerService, FileService } from '../../services';

import {
  icFacebook,
  icTwitter,
  icInstagram,
  icWorld,
  icYoutube,
  imgAgenOren,
  imgSimpanSejuta,
  imgPastiCuan,
} from '../../assets';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

const Header = (props) => {
  const getBanner = async () => {
    try {
      const response = await BannerService.getBanner(3);
      const data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];

        let res;

        if (
          element.image.fileName.endsWith('.png') ||
          element.image.fileName.endsWith('.jpg') ||
          element.image.fileName.endsWith('.jpeg') ||
          element.image.fileName.endsWith('.svg')
        ) {
          res = await FileService.getFile(element.image.fileName);
        } else {
          res = await FileService.getFile('noimage.png');
        }

        let responseData = {
          id: element.id,
          image: (
            <img
              className="center-img"
              src={`data:image/webp;base64,${res.data}`}
              alt={element.fileName}
            />
          ),
          title: element.title,
          subTitle: element.subTitle,
        };
        data.push(responseData);
      }
      return data;
      // setBannerData(data);
    } catch (err) {}
  };

  const {
    isLoading,
    data: bannerData,
    isError,
  } = useQuery('banner', getBanner);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    auto: false,
    arrows: false,
    dotsClass: 'dots',
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          auto: false,
          arrows: false,
          vertical: false,
          verticalSwiping: false,
          dotsClass: 'dots-row',
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  const isHttps = (url) => {
    return url?.includes('https');
  };

  const icons = [
    { logo: icWorld, name: 'worlds' },
    {
      logo: icFacebook,
      name: 'facebook',
    },
    {
      logo: icTwitter,
      name: 'twitter',
    },
    {
      logo: icInstagram,
      name: 'instagram',
    },
    {
      logo: icYoutube,
      name: 'youtube',
    },
  ];

  return (
    <div className="pb-8 pt-8 sm:pt-5 4xl:py-28  ">
      {isLoading ? (
        <HeaderSkeleton />
      ) : !isError ? (
        <Slider {...settings}>
          {bannerData?.map((item) => {
            return (
              <div className=" sm:mb-10 sm:p-5 " key={item.id}>
                <div className="grid grid-cols-12 md:grid-cols-1 s-400:mt-5 justify-center items-center   ">
                  <div className="col-span-6">
                    <div className="flex flex-col justify-center items-center  sm:px-0 pl-20 s-400:pl-0 sm:pl-0   md:pl-0   ">
                      <div className="container  items-center justify-center  ">
                        <Fade left>
                          <div className="mb-5 ">
                            <p className="text-white  font-ff-secondary  text-55px s-500:text-2xl  md:text-4xl ">
                              {item.title}
                            </p>
                          </div>
                        </Fade>
                        <Fade right>
                          <div className=" mb-8 ">
                            <p className="text-white font-ff-primary xl:text-sm lg:text-xs text-lg  ">
                              {item.subTitle}
                            </p>
                          </div>
                        </Fade>

                        <Fade bottom>
                          <div className="mb-12 sm:mx-auto">
                            <Link to={`selengkapnya/${window.btoa(item.id)}`}>
                              <button className="font-ff-secondary text-white px-10 py-4 bg-oren-500 hover:bg-oren-300  rounded-full ">
                                Selengkapnya
                              </button>
                            </Link>
                          </div>
                        </Fade>

                        <div className="flex flex-row space-x-7 md:justify-between sm:px-10 s-400:px-0  md:pl-0 sm:space-x-0 ">
                          {isHttps(props.websiteUrl) && (
                            <a
                              href={`${props.websiteUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={icWorld} alt={'Website KOPNUSPOS'} />
                            </a>
                          )}

                          {isHttps(props.facebookUrl) && (
                            <a
                              href={`${props.facebookUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={icFacebook}
                                alt={'Facebook KOPNUSPOS'}
                              />
                            </a>
                          )}

                          {isHttps(props.icTwitter) && (
                            <a
                              href={`${props.icTwitter}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={icTwitter} alt={'Twitter KOPNUSPOS'} />
                            </a>
                          )}

                          {isHttps(props.instagramUrl) && (
                            <a
                              href={`${props.instagramUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={icInstagram}
                                alt={'Instagram KOPNUSPOS'}
                              />
                            </a>
                          )}

                          {isHttps(props.youtubeUrl) && (
                            <a
                              href={`${props.youtubeUrl}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={icYoutube} alt={'Youtube KOPNUSPOS'} />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6  relative 	 ">
                    <div style={{ maxWidth: '411px', minHeight: '446px' }}>
                      {item.image}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : (
        <Slider {...settings}>
          <div className="my-8  sm:mt-10 sm:mb-10 sm:p-5 ">
            <div className="grid grid-cols-2 md:grid-cols-1 s-400:mt-5  ">
              <div className="flex flex-col justify-center  sm:px-0 pl-20 s-400:pl-0 sm:pl-0   md:pl-0   ">
                <div className="container  items-center justify-center  ">
                  <div className="mb-5 ">
                    <p className="text-white  font-ff-secondary  text-55px xl:text-2xl   ">
                      #AGENOREN
                    </p>
                  </div>

                  <div className=" mb-8 ">
                    <p className="text-white font-ff-primary xl:text-sm lg:text-xs text-lg  ">
                      Jadi AGEN OREN, bisa dapetin Vespa keren.
                    </p>
                  </div>

                  <div className="mb-12 sm:mx-auto">
                    <a href=" https://kopnuspos.com/agen-oren/" target="_blank">
                      <button className="font-ff-secondary text-white px-10 py-4 bg-oren-500 hover:bg-oren-300  rounded-full ">
                        Selengkapnya
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-row space-x-7 md:justify-between sm:px-10 s-400:px-0  md:pl-0 sm:space-x-0 ">
                    {icons.map((icon, index) => {
                      return (
                        <div key={index}>
                          <img src={icon.logo} alt={icon.name} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className=" flex  sm:pt-8  items-center justify-center 	 ">
                <img src={imgAgenOren} alt="logo phone" />
              </div>
            </div>
          </div>

          <div className="my-8  sm:mt-10 sm:mb-10 sm:p-5 ">
            <div className="grid grid-cols-2 md:grid-cols-1 s-400:mt-5  ">
              <div className="flex flex-col justify-center  sm:px-0 pl-20 s-400:pl-0 sm:pl-0   md:pl-0   ">
                <div className="container  items-center justify-center  ">
                  <div className="mb-5 ">
                    <p className="text-white  font-ff-secondary  text-55px xl:text-2xl   ">
                      #SIMPANSEJUTA
                    </p>
                  </div>

                  <div className=" mb-8 ">
                    <p className="text-white font-ff-primary xl:text-sm lg:text-xs text-lg  ">
                      Simpan sejuta, bisa bawa pulang Vespa
                    </p>
                  </div>

                  <div className="mb-12 sm:mx-auto">
                    <a href=" https://kopnuspos.com/agen-oren/" target="_blank">
                      <button className="font-ff-secondary text-white px-10 py-4 bg-oren-500 hover:bg-oren-300  rounded-full ">
                        Selengkapnya
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-row space-x-7 md:justify-between sm:px-10 s-400:px-0  md:pl-0 sm:space-x-0 ">
                    {icons.map((icon, index) => {
                      return (
                        <div key={index}>
                          <img src={icon.logo} alt={icon.name} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className=" flex  sm:pt-8  items-center justify-center 	 ">
                <img src={imgSimpanSejuta} alt="banner " />
              </div>
            </div>
          </div>
          <div className="my-8  sm:mt-10 sm:mb-10 sm:p-5 ">
            <div className="grid grid-cols-2 md:grid-cols-1 s-400:mt-5  ">
              <div className="flex flex-col justify-center  sm:px-0 pl-20 s-400:pl-0 sm:pl-0   md:pl-0   ">
                <div className="container  items-center justify-center  ">
                  <div className="mb-5 ">
                    <p className="text-white  font-ff-secondary  text-55px xl:text-2xl   ">
                      #PASTICUAN
                    </p>
                  </div>

                  <div className=" mb-8 ">
                    <p className="text-white font-ff-primary xl:text-sm lg:text-xs text-lg  ">
                      Punya banyak Uang, Gak perlu nunggu sampe Ubanan. Daftar
                      AGEN OREN Sekarang, PASTI CUAN.
                    </p>
                  </div>

                  <div className="mb-12 sm:mx-auto">
                    <a href=" https://kopnuspos.com/agen-oren/" target="_blank">
                      <button className="font-ff-secondary text-white px-10 py-4 bg-oren-500 hover:bg-oren-300  rounded-full ">
                        Selengkapnya
                      </button>
                    </a>
                  </div>

                  <div className="flex flex-row space-x-7 md:justify-between sm:px-10 s-400:px-0  md:pl-0 sm:space-x-0 ">
                    {icons.map((icon, index) => {
                      return (
                        <div key={index}>
                          <img src={icon.logo} alt="banner " />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className=" flex  sm:pt-8 items-center justify-center 	 ">
                <img src={imgPastiCuan} alt="banner " />
              </div>
            </div>
          </div>
        </Slider>
      )}
    </div>
  );
};

export default Header;
