const api_version = 'v1.0'


const api = {
    member: {
        check: `${process.env.REACT_APP_API_URL}api/${api_version}/website/check-member`,
        register: `${process.env.REACT_APP_API_URL}api/${api_version}/website/register-member`,
        newRegister: `${process.env.REACT_APP_API_URL}api/${api_version}/website/register-landing-page`,
    },

    content: {
        banner: `${process.env.REACT_APP_API_URL}api/${api_version}/website/banners`,
        video: `${process.env.REACT_APP_API_URL}api/${api_version}/website/videos`
    },

    file: `${process.env.REACT_APP_API_URL}api/${api_version}/asset/images`,

    configuration: `${process.env.REACT_APP_API_URL}api/${api_version}/website/configurations`,

    privacyPolicy: `${process.env.REACT_APP_API_URL}api/${api_version}/master/privacy-policies`,

};

export default api;
