import React, { useEffect, useState } from 'react';
import { lgKopnuspos, bgOren } from '../../assets';
import { SubFooter } from '../../components';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { GA, PrivacyPolicyService } from '../../services';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const [content, setContent] = useState('');

  const getPrivacyPolicy = () => {
    PrivacyPolicyService.getPrivacyPolicy(3).then((response) => {
      setContent(response.data.content);
    });
  };

  useEffect(() => {
    // GoogleAnalytics.pageView('privacy policy');

    getPrivacyPolicy();
    GA.pageView(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
      <div>
        <div
          className=" bg-no-repeat bg-cover py-10 px-20 s-400:px-5 s-500:px-5 s-600:px-5 w-full "
          style={{ backgroundImage: `url(${bgOren})` }}
        >
          <div className="glass-effect ">
            <div className="  py-18  sm:p-0  ">
              <div className="flex justify-between items-center p-20 sm:p-10 ">
                <div
                  className="flex items-center	gap-2 cursor-pointer"
                  onClick={() => navigate('/')}
                >
                  <svg
                    width="11"
                    height="20"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6195 0.989793C10.5034 0.873385 10.3655 0.781028 10.2136 0.718012C10.0617 0.654995 9.89893 0.622559 9.73451 0.622559C9.5701 0.622559 9.40729 0.654995 9.25543 0.718012C9.10357 0.781028 8.96563 0.873385 8.84951 0.989793L0.539515 9.29979C0.446811 9.39231 0.373263 9.5022 0.323082 9.62317C0.272901 9.74414 0.24707 9.87383 0.24707 10.0048C0.24707 10.1358 0.272901 10.2654 0.323082 10.3864C0.373263 10.5074 0.446811 10.6173 0.539515 10.7098L8.84951 19.0198C9.33951 19.5098 10.1295 19.5098 10.6195 19.0198C11.1095 18.5298 11.1095 17.7398 10.6195 17.2498L3.37951 9.99979L10.6295 2.74979C11.1095 2.26979 11.1095 1.46979 10.6195 0.989793Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white text-base">Back</p>
                </div>

                <img src={lgKopnuspos} alt="logo" className="md:hidden" />
              </div>

              <div className="grid grid-cols-12  sm:grid-cols-1 sm:py-0 ">
                <div className="col-span-5 content-center sm:mb-8	">
                  <div className="flex flex-col">
                    <div className="text-5xl text-white font-ff-secondary lg:text-4xl py-48 sm:py-5">
                      <div className="flex flex-col  items-center pt-4 pr-24 sm:py-5 sm:justify-center sm:pr-0 sm:pt-0">
                        <div>Privacy </div>
                        <div>Policy</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-7 pb-10 pr-24 sm:pr-0 sm:mx-5">
                  <div className="container bg-white px-8 py-11 rounded-30px sm:px-8 sm:py-5   ">
                    <div className="container overflow-scroll  h-37rem">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: window.atob(content),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubFooter />
      </div>
    </>
  );
};

export default PrivacyPolicy;
