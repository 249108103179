import React, { useEffect, useState } from 'react';
import { useScrollSection, Section } from 'react-scroll-section';
import { bgOren } from '../../assets';
import {
  Carousel,
  Content,
  Register,
  NavMobile,
  Modal,
  WaCall,
  NavMain,
  NavBanner,
  MainFooter,
  FABWa,
} from '../../components';

import { Helmet } from 'react-helmet';
import useScroll from '../../hooks/useScroll';
import { GA } from '../../services';
import useStore from '../../store';

const Landing = () => {
  const promotionSection = useScrollSection('promotion');
  const videoSection = useScrollSection('video');
  const pendaftaranSection = useScrollSection('pendaftaran');
  const kontakSection = useScrollSection('kontak');

  const [isOpen, setIsOpen] = useState(false);
  const [scroll] = useScroll();

  const zConfigData = useStore((state) => state.configData);
  const fetchConfig = useStore((state) => state.fetchConfig);

  useEffect(() => {
    fetchConfig();
    GA.pageView('homepage');
  }, []);

  return (
    <>
      <Helmet>
        <title>{zConfigData.webName}</title>
        <meta name="description" content={zConfigData.meta} />
      </Helmet>
      <div>
        <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
          <WaCall {...zConfigData} />
        </Modal>
        <NavMain />
        <NavMobile />
        <Section id="promotion">
          <div
            id="header"
            className=" bg-no-repeat bg-cover py-10 px-20 s-400:px-5  s-500:px-5 w-screen h-screen md:h-full lg:h-full  "
            style={{
              backgroundImage: `url(${bgOren})`,
              display: 'flex',
              // justifyContent: 'center',
            }}
          >
            <div className="flex justify-center w-full h-full ">
              <div className="glass-effect md:mt-16  ">
                <div className=" px-24   sm:p-5 w-full   ">
                  <NavBanner
                    logo={zConfigData}
                    promotionSection={promotionSection}
                    videoSection={videoSection}
                    pendaftaranSection={pendaftaranSection}
                    kontakSection={kontakSection}
                  />
                  <Carousel {...zConfigData} />
                </div>
              </div>
            </div>
          </div>
        </Section>

        <div
          className={
            window.innerHeight + scroll >= document.body.offsetHeight
              ? `hidden `
              : `fixed right-5 bottom-5 z-20 animate-fadeIn  ${'block'}`
          }
        >
          <div className="flex justify-between items-center gap-5 ">
            <div className="bg-white  px-3 py-2  place-items-center rounded-xl flex">
              <p className="m-auto font-ff-secondary"> Hubungi Kami</p>
            </div>
            <div onClick={(e) => setIsOpen(true)}>
              <FABWa />
            </div>
          </div>
        </div>

        <Section id="video">
          <Content />
        </Section>
        <Section id="pendaftaran">
          <Register />
        </Section>
        <Section id="kontak">
          <MainFooter {...zConfigData} />
        </Section>
      </div>
    </>
  );
};

export default Landing;
